@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply text-purple-100 font-sans;
  }

  h1 {
    @apply text-4xl font-bold leading-tight text-stroke-slate-700;
  }
  h2 {
    @apply text-3xl font-bold leading-tight text-stroke-slate-700;
  }
  h3 {
    @apply text-2xl font-bold leading-tight text-stroke-slate-700;
  }
  h4 {
    @apply text-xl font-bold leading-tight text-stroke-slate-700;
  }
}

:root {
  --from-color: rgb(78, 78, 78);
  --to-color: rgb(78, 78, 78);
}
